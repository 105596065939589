/*==================================
page
==================================*/
.page_noHeader
{
	padding-top: 25px;
}
/*==================================
header
==================================*/
.header
{
	height: 250px;
}

.header__desktop
{
	margin-top: 25px;
}

.header_background
{
	background-size: cover;
    background-position: bottom right;
}

.header_textPanel
{
	width: 100%;
	flex-direction: column;
    align-items: flex-start;
    backdrop-filter: brightness(0.4);
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 0;
	padding-bottom: 0;
	height: auto;
}

.header_textPanel__desktop
{
	padding-top: 25px;
	padding-bottom: 25px;
	padding-left: 50px;
	width: 50%;
	height: 100%;
	backdrop-filter: none;
	align-items: center;
}
/*==================================
footer
==================================*/
.footer
{
	margin-top: auto;
	margin-bottom: 25px;
	width: 100%;
}

.footer_image
{
	height: 65px;
    background: var(--MACZKI_palette_orange);
    border-radius: 50%;
}
/*==================================
footer
==================================*/
.sectionHeader
{
	border-bottom: 2px solid var(--MV_palette_moonYellow);
    border-image: linear-gradient(
		90deg,
		transparent,
		transparent,
		var(--MV_palette_moonYellow),
		transparent,
		transparent
	) 1 stretch;
}