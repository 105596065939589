/*==================================
home
==================================*/
.homeHeader
{
    text-align: center;
	z-index: 1;
}

.homeBackground
{
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
/*==================================
earn/token
==================================*/
.earnToken_warning
{
    background: #f7a91d !important;
	width: 100%;
}

.earnToken_buySell
{
    width: 300px;
    height: 400px;
    text-align: center;
}

.earnToken_button
{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.earnToken_stats
{
    justify-content: space-between;
}

.earnToken_breakdown
{
	height: 300px;
}